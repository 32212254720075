<template>
   <y-layout-body-menu :menu="[
       {title:'分类',path:'/card/merchant/cate'},
       {title:'入驻中',path:'/card/merchant/manage'},
       {title:'已停用',path:'/card/merchant/stop'},
   ]">
     <router-view/>
   </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>